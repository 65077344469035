import React, {Component} from 'react'
import { Info } from 'react-feather';
import ZoomImg from '../components/ZoomImg';
import '../style.scss';

var insights_cover = require('../assets/insights_cover.png');
var insights_value = require('../assets/insights_value.png');
var insights_process1 = require('../assets/insights_process1.png');
var insights_process2 = require('../assets/insights_process2.png');
var insights_graph = require('../assets/graph.gif');
var insights_help = require('../assets/insights_help.png');
var insights_screens = require('../assets/insights_screens.png');
var insights_end = require('../assets/insights_end.png');

class Insights extends Component {

  constructor(props){
    super(props)
    this.state = {
       
    }
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }


  render(){

    return(
  
    <div className="App" id="fade-in1">  
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <a onClick={this.props.history.goBack} style={{cursor:'pointer'}}>Back</a>
          <span style={{alignContent:'end'}}>* Click on images to zoom in</span>
        </div> 
        {/* <p><a onClick={this.props.history.goBack}>Back</a> <span style={{alignContent:'end'}}>* Click on images to zoom in</span></p> */}
            <br/>
           
            <ZoomImg src={insights_cover} style={{width:'100%'}}/>
            
            <div className='content_body'>
              
                <div style={{width:'600px'}}>
                <br/>
                  <br/>
                  <h1>Rich insights dashboard for Microsoft commercial partners</h1>
                  <br/>
                  <br/>
                  <h2>From concepts to wireframing and visuals, it was challenging to provide answers to business questions using clean dashboards.</h2>
                  <br/><br/>
                  
                  <p>Insights is a unified reporting dashboard in Partner Center for Microsoft’s Commercial partners who are enrolled in the Microsoft Partner Network (MPN) program.</p>
                  <br/>
                  <div style={{backgroundColor:'#ffede6',borderRadius:'4px',padding:'6px 20px 6px 20px'}}>
                    <p><Info size={16} style={{verticalAlign:'text-top'}} /> Note</p>
                    <p>In reality, this project contains confidential Information. I modified a few things and described them on the high-level to help you understand what I did and achieved.</p>
                  </div>
                  <br/><br/>
                  <h3>Overview</h3>
                  <p>The Insights dashboard provides a 360-degree view of key performance indicators (KPI) across Cloud products such as Office, Azure, Dynamics, and licensing models. It exposes a rich set of KPI reports that can help make data-driven decisions for the partner's organization.</p>
                  <br/>
                  <br/>
                  
                  <h4>Problem</h4>
                  <h3>How might we provider a better and unifed reporting to partners at Microsoft</h3>
                  <p>Partners today are heavily invested in building their business around Microsoft. Every action of each partner and motivation behind it is imperative for their business growth. Helping partners with insights into their business around Microsoft is the need of the hour.</p>
                  <br/>
                  <br/>
                  
                  <h4>Value proposition</h4>
                  <p>We are set to create experience that is richer and more intuitive.</p>
                  <ZoomImg src={insights_value} style={{width:'100%'}}/><br/>
                  <p style={{width:'330px'}}>In the process of understanding our partner's business, it is important to identify what piece of information is crucial and what is not. Here are some of the most important questions that fetched us enough insights.</p>
                  <ZoomImg src={insights_process1} style={{width:'100%'}}/><br/>
                  <br/>
                  <br/>
                  
                  <h4>My role</h4>
                  <h3>Brainstorming sessions and workshops with partners help in getting more clarity on their needs.</h3>
                  <p style={{width:'330px'}}>Considering the opportunities that Microsoft has to work on to provide insights to partners, I need to come up with high-level mocks to ignite the discussion. Before starting with it, I have a bunch of questions about the insights. One such question is how might we classify the order of insights. I sit with PMs to discuss and classify insights as primary and secondary ones.</p>
                  <ZoomImg src={insights_process2} style={{width:'100%'}}/><br/>
                  <center><span>Whiteboarding ideas in brainstorming sessions</span></center>
                  <br/>
                  <br/>
                  <p style={{width:'330px'}}>Next step is to classify the questions we are answering through the dashboards. For example, "What is the trend of my orders over time?" is a <b>comparison.</b></p>
                  <br/>
                  <br/>

                  <h3>I pick up and put together the graphs that help in representing the answers better.</h3>
                  <br/>
                  <div style={{backgroundColor:'#ffede6',borderRadius:'4px',padding:'6px 20px 6px 20px'}}>
                    <p>The logical grouping of the graphs to form a valuable insight is a thing I was perfecting for a while now. That helps in creating quick mocks and take feedback more often and do more iterations.</p>
                  </div>
                  <br/>

                  <p>Once I am ready with the first set of high fidelity wireframes, PMs took it partners for seeking feedback. Here, PMs find opportunities while they interact with partners.</p>
                  <p>Post receiving feedback, I updated the mock and ran through my team to seek feedback and took a vote of confidence with the senior team at Microsoft to pitch this to partners.</p>
                  <br/><br/>

                  <h4>Highlights</h4>
                  <h3>We used the AMCHARTS library to create an interactive experience for partners.</h3>
                  <p style={{width:'330px'}}>We achieved consistency among all the reports we build under insights.</p>
                  <ZoomImg src={insights_graph} style={{width:'100%'}}/><br/>
                  <br/>
                  <br/>                  

                  <h3>Seeking feedback from partners and capturing pain points.</h3>
                  <p style={{width:'330px'}}>Another interesting feature we added to our dashboards is having a like and dislike button below each visual card. It enables partners to provide specific feedback about each visual with a single click. The feedback is monitored closely and acts as a source to record pain points from partners.</p>
                  <ZoomImg src={insights_help} style={{width:'100%'}}/><br/>
                  <br/>
                  <br/>
                  
                  <h4>Feedback</h4>
                  <h3>After first launch of the insights feature, we got to hear from our big partners.</h3>
                  <br/><br/>
                  <div style={{backgroundColor:'#ffede6',borderRadius:'4px',padding:'6px 20px 6px 20px'}}>
                    <p>Feedback from partners:</p>
                    <ul>
                        <li><p>These reports look very impressive and they sound like music to my ears!</p></li>
                        <li><p>I have been looking at the information in there and it is a massive improvement on what we previously had so is greatly appreciated by myself and the wider business - a huge thanks!</p></li>
                        <li><p>All in all, this is a huge and very welcomed step in terms of insights and BI from a CSP and partner perspective, so thank you very much!</p></li>
                        <li><p>I really like the information that's available. Right now we're doing a lot of this manually. I've also had a chance to review the data on a few occasions now and it looks quite good.</p></li>
                    </ul>
                  </div>
                  <br/>
                  <br/>
                  
                  <h4>Dashboards UI</h4>
                  <h3>Bunch of dashboards I designed for Insights feature in Partner Center.</h3>
                  <ZoomImg src={insights_screens} style={{width:'100%'}}/><br/>

                  <div className='space'></div>

                  <div>
                    <h4>Achievements and next steps</h4>
                    <h3>Partners are positive about Insights feature that helped them get rich insights of their business. Now its time to make these dashboards accessible and work on modernizing to bring cohesive look and feel.</h3>
                    <br/>
                    <br/>
                    {/* <ZoomImg src={insights_end} style={{width:'100%'}}/><br/> */}
                  </div>
                  <br/>
                  <br/>
        
                  <hr className="hr-text" data-content="Design has no end" />
                  <br/>
                  <br/>
                  
                  </div>
                  </div>
                  
                 
        <br/><br/>
    </div>

    )
  }

  

}
export default Insights
