import React, {Component} from 'react'
import { Mic, Disc } from 'react-feather';
import ZoomImg from '../components/ZoomImg';

var time = require('../assets/time.jpg');
var glass_overview = require('../assets/glass_overview.jpg');
var time_pollquiz = require('../assets/time_pollquiz.png');
var time_pollstats = require('../assets/time_pollstats.png');
var time_pollstats1 = require('../assets/time_pollstats1.png');
var time_polluser = require('../assets/time_polluser.png');
var time_productline = require('../assets/time_productline.png');
var time_sitesearchstats = require('../assets/time_sitesearchstats.png');
var time_sitesearchstats1 = require('../assets/time_sitesearchstats1.png');
var time_propose = require('../assets/time_propose.png');
var time_propose2 = require('../assets/time_propose2.png');
var time_propose3 = require('../assets/time_propose3.png');
var time_creditlimitrevision = require('../assets/time_creditlimitrevision.png');
var time_creditlimitrevision2 = require('../assets/time_creditlimitrevision2.png');



class MeredithWork extends Component {

  constructor(props){
    super(props)
    this.state = {
       
    }
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render(){

    return(
  
    <div className="App" id="fade-in1">  
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <a onClick={this.props.history.goBack} style={{cursor:'pointer'}}>Back</a>
          <span style={{alignContent:'end'}}>* Click on images to zoom in</span>
        </div> 
        {/* <p><a onClick={this.props.history.goBack}>Back</a> <span style={{alignContent:'end'}}>* Click on images to zoom in</span></p> */}
            <br/>
           
            <ZoomImg src={time} style={{width:'100%'}}/>
            
            <div className='content_body'>
              
                <div style={{width:'600px'}}>
                <br/>
                  <br/>
                  <h1>Time Inc. <br/>aka Meredith</h1>
                  <br/>
                  <br/>
                  <h2>Bunch of interaction and visual work in media domain.</h2>
                  <p>Find below the work of projects I worked during my tenure at Time Inc (aka Meredith). I curated experiences for content creators, designed reporting dashboards and big data applications.</p>
                  <br/><br/>
                  <br/>
                  <br/>
                  <h2>GENIE</h2>
                  <p>A platform with products that help to maintain better user engagement within brands.</p>
                  <p>Genie is a hub for advertising products. The goal is to reach a wider audience and to capture user choices from every connection we make across the globe through the brands. The end-users of these products are of two types.</p>
                  
                  <div style={{backgroundColor:'#F7FAFC',borderRadius:'4px',padding:'16px 32px 8px 32px'}}>
                    <h3>👨🏻‍🏫  Content creator</h3><p>Creates an ad, poll, quiz, or a game and publishes it on a website</p>
                </div>
                  <br/>
                  <div style={{backgroundColor:'#F7FAFC',borderRadius:'4px',padding:'16px 32px 8px 32px'}}>
                    <h3>🧑🏻‍💻  Content consumer</h3><p>Browses the website and views an ad, takes a poll/quiz, or plays a game</p>
                </div>
                <p>The challenge here is to maintain a seamless user experience across the product. It has 2 flavors. One includes the process of creating an ad, poll, or random game. Other being the front-facing widgets that get embedded into the websites with the same branding.</p>
                <p>The product line includes:</p>
                  <ZoomImg src={time_productline} style={{width:'100%',borderRadius:'10px'}}/>
                  <br/>
                  <br/>
                  <p>Screens for content creation</p>
                  <ZoomImg src={time_pollquiz} style={{width:'100%'}}/>
                  <br/>
                  <br/>
                  <p>End user view of the content</p>
                  <ZoomImg src={time_polluser} style={{width:'100%'}}/>
                  <br/><br/>
                  <p>Stats of the usage</p>
                  <ZoomImg src={time_pollstats1} style={{width:'100%',borderRadius:'10px'}}/>
                  <ZoomImg src={time_pollstats} style={{width:'100%',borderRadius:'10px'}}/>
                  
                  <div className='space'></div>
                  <hr className="hr-text" data-content="Next project" />
                  <br/>
                  <br/>

                  <h2>Site search stats</h2>
                  <h3>Brief</h3>
                  <p>A view into real-time stats of content search. All the search instances offer a great level of detail about user search patterns. This system captures every detail and the user behavior on the system.</p>
                  <br/>
                  <h3>Principles</h3><br/>
                  <div style={{backgroundColor:'#F7FAFC',borderRadius:'4px',padding:'16px 32px 8px 32px'}}>
                    <p>Seamless and aesthetic user interface</p>
                    <p>Easy and quicker view at the high-level user choices</p>
                </div>
                  <br/><br/>
                  <h3>Final visuals</h3><br/>
                  <ZoomImg src={time_sitesearchstats} style={{width:'100%',borderRadius:'10px'}}/>
                  <br/><br/>
                  <ZoomImg src={time_sitesearchstats1} style={{width:'100%'}}/>
                  <br/><br/>
                  <hr className="hr-text" data-content="Next project" />
                  <br/><br/>


                  <h2>Propose</h2>
                  <p>A system that empowers the sales team to offer better quotations to ad-publishers. Taking into consideration the preferences of the ad-publisher, the sales team with the help of this system will project the possibilities of the targeted audience. Propose is empowered with a great set of algorithms that drills into the big-data of the subscribed users.</p>
                  <br/>
                  <h3>Goal</h3><br/>
                  <div style={{backgroundColor:'#F7FAFC',borderRadius:'4px',padding:'16px 32px 8px 32px'}}>
                    <p>Find out the targeted audience for an ad.</p>
                  </div>
                  <br/><br/>
                  <h3>Final visuals</h3><br/>
                  <ZoomImg src={time_propose} style={{width:'100%',borderRadius:'10px'}}/>
                  <br/><br/>
                  <ZoomImg src={time_propose2} style={{width:'100%',borderRadius:'10px'}}/>
                  <br/><br/>
                  <ZoomImg src={time_propose3} style={{width:'100%',borderRadius:'10px'}}/>
                  <br/><br/>
                  <hr className="hr-text" data-content="Next project" />
                  <br/><br/>

                  <h2>Credit limit revision</h2>
                  <p>Credit limit revision is a risk management process to ensure - services sold on credit terms do not result in bad debt.</p>
                  <br/>
                  
                  <h3>Final visuals</h3><br/>
                  <ZoomImg src={time_creditlimitrevision} style={{width:'100%'}}/>
                  <br/><br/>
                  <ZoomImg src={time_creditlimitrevision2} style={{width:'100%'}}/>
                  <br/><br/>
                  
                  
                  
                  <hr className="hr-text" data-content="End" />
                  

                  </div>
              </div>
        
        <br/><br/>
    </div>

    )
  }

  

}
export default MeredithWork
