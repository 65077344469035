import React, {Component} from 'react'
import { Info } from 'react-feather';
import ZoomImg from '../components/ZoomImg';

var campus_cover = require('../assets/campus_cover.jpg');
var campus_findings = require('../assets/campus_findings.jpg');
var campus_ia = require('../assets/campus_ia.jpg');
var campus_journeymapping = require('../assets/campus_journeymapping.jpg');
var campus_persona = require('../assets/campus_persona.jpg');
var campus_questions = require('../assets/campus_questions.jpg');
var campus_taskflow = require('../assets/campus_taskflow.jpg');
var campus_wireflow1 = require('../assets/campus_wireflow1.jpg');
var campus_wireflow2 = require('../assets/campus_wireflow2.jpg');
var campus_cover1 = require('../assets/campus_cover1.jpg');
var campus_visual = require('../assets/campus_visual.png');
var campus_visual2 = require('../assets/campus_visual2.jpg');


class SmartCampus extends Component {

  constructor(props){
    super(props)
    this.state = {
       
    }
  }
  
    componentDidMount() {
    window.scrollTo(0, 0)
  }


  render(){

    return(
  
    <div className="App" id="fade-in1">  
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <a onClick={this.props.history.goBack} style={{cursor:'pointer'}}>Back</a>
          <span style={{alignContent:'end'}}>* Click on images to zoom in</span>
        </div> 
        {/* <p><a onClick={this.props.history.goBack}>Back</a> <span style={{alignContent:'end'}}>* Click on images to zoom in</span></p> */}
            <br/>
           
            <ZoomImg src={campus_cover} style={{width:'100%'}}/>
            
            <div className='content_body'>
              
                <div style={{width:'600px'}}>
                <br/>
                  <br/>
                  <h1>Smart Campus</h1>
                  <br/>
                  <br/>
                  <h2>Digital solution to smoothly communicate for campuses</h2>
                  <br/><br/>
                  <div style={{backgroundColor:'#ffede6',borderRadius:'4px',padding:'6px 20px 6px 20px'}}>
                    <p><Info size={16} style={{verticalAlign:'text-top'}} /> Back story</p>
                    <p>It is 2014, I was invited to SVECW campus to deliver a talk on mobile apps revolution. As an industry expert (working on Google Glass and Android Wear), I delivered the talk.</p>
                    <span>Before I left the campus, I met with the principal and he appreciated my work. He shared with me that there are a few things on our campus that I find tedious. He explained how students reach out to his office for some approvals each day. He doesn't want their valuable time to be wasted. He was thinking whether digitizing this can help them in saving everyone's time.</span>
                    <span>I quickly thought the Smart DC application which I created in Infosys Hackathon would be a real need of the hour for this campus. I discussed the same with him and he showed interest in seeing an MVP of the same for this campus.</span>
                    <span>I decided to take up the curious case of this campus. The idea here is to use my previous problem-solving methodology and see if I can make things better and smarter. I know it is very difficult to manage students on such a large scale.</span>
                    <span>I understood that this campus needs an update in its processes to manage things better.</span>
                    <br/><br/>
                  </div>
                  <br/>
                  <br/>
                  <h3>Design brief</h3>
                  <p>How can I help students and management communicate and collaborate better, smarter?. In return, save their time to focus more on academics rather than secondary campus activities.</p>
                  <br/>
                  <br/>
                  <h3>My approach</h3>
                  <br/><br/>
                  <div style={{backgroundColor:'#ffede6',borderRadius:'4px',padding:'16px 20px 16px 20px'}}>
                    <p>"Always design a thing by considering it in its next larger context - a chair in a room, a room in a house, a house in an environment, an environment in a city plan." - Eliel Saarinen</p>
                  </div>
                  <p>In our scenario, it's a student in a class, a class in a department, a department on campus, a campus in a city.</p>
                  <br/>
                  <br/>
                  {/* <ZoomImg src={glass_overview} style={{width:'100%'}}/> */}
                  
                  <h3>Research objective</h3>
                  <p>The research objective is to explore each context and develop empathy with the users involved in it. If done for all contexts, we get a bigger picture of how the ecosystem functions and that leads to a better approach in solving the problem. I mean to derive a better problem statement.</p>
                  <div style={{backgroundColor:'#E4F2FF',borderRadius:'4px',padding:'16px 20px 16px 20px'}}>
                    <p>👤 &nbsp;Our <u>users</u> are <u>students</u> in a class.</p>
                  </div>
                  <br/><br/>
                  <div style={{backgroundColor:'#ffede6',borderRadius:'4px',padding:'16px 20px 16px 20px'}}>
                    <p>🎯 &nbsp;Our <u>goal</u> is to <u>collect data</u> about their day to day <u>campus activities.</u></p>
                  </div>
                  
                  <p>I need to empathize with students to collect some <mark>qualitative information</mark> from them. I need a qualitative approach to interact with students. I choose to <mark>interview</mark> a few of them and also <mark>directly observe</mark> while they move around to seek some help when on campus. </p>
                  <div style={{backgroundColor:'#f5f5f5',borderLeft:'4px solid #CCCCCC',borderRadius:'4px',padding:'16px 32px'}}>
                  <span>Going back to my college days, As a student, I tried to pen down some of the things which I found tedious. This helped me prepare a better questionnaire to understand today's campus life from students. I carried out <mark>further research</mark> with some students when I visited college next talk on the 'Startup ecosystem'. I interviewed and observed the students, to understand today's campus life.
                    <br/><br/>I gave them tasks to perform (one of which is to seek information about an upcoming department event and nominate themselves) and documented what all they have to do for it.
                  </span>
                  </div>

                  <br/>
                  <br/>
                  <p><b>Here are a couple of interview questions from the questionnaire (that included both open and closed questions) :</b></p>
                  <ZoomImg src={campus_questions} style={{width:'100%'}}/>

                  <h3>Key findings</h3>
                  <ZoomImg src={campus_findings} style={{width:'100%'}}/>

                  <h3>Persona</h3>
                  <ZoomImg src={campus_persona} style={{width:'100%'}}/>

                  <p>From the research and ethnographic study i started writing down a narrative of a student's journey.</p>
                  <br/>
                  <br/>

                  <h3>Journey mapping</h3>
                  <p>I tried to capture the set of actions students are taking, the mindsets as they take them, and the emotional side-effects (frustrated?, excited?).</p>
                  <ZoomImg src={campus_journeymapping} style={{width:'100%'}}/>
                  <p>At the end of user journey mapping, I have turned student pain points into opportunities that I can work on. Thinking about "How might we" help students in overcoming their pain points.<br/>
                  Turning my opportunities into "how might we" questions helped me in creating important features that I can provide students.</p>
                  <p>Insights helped me know what are the necessary features for this application. The idea here is to create a single middleware application that facilitates smooth communication between students and campus management. The application must work for both parties.</p>
                  <br/><br/>
                  <h3>Context of use</h3>
                  <p>So far we have collected essential inputs to our problem definition</p>
                  <h4>What?</h4>
                  <ul>
                    <li><p>Potential users: Students and lecturers</p></li>
                    <li><p>Tasks: Perform quick actions like raise a request, approve request, check today's schedule</p></li>
                    <li><p>Environment: While in campus, during the classroom time</p></li>
                    <li><p>Constraints: Management and students are present at the same time in campus and not available post schedule</p></li>
                  </ul>
                  <h4>How?</h4>
                  <ul>
                    <li><p>Talking to these users using interviews and taking inputs using survey</p></li>
                    <li><p>Ethnographic research to understand how they perform their tasks today</p></li>
                    <li><p>Visited the campus and understood their geographic</p></li>
                    <li><p>Talking to lecturers helped in gathering more information about shortcomings</p></li>
                  </ul>
                  <br/><br/>

                  <h3>Vision</h3>
                  <p>To create a platform that facilitates smooth communication between students and campus management.</p>

                  <h3>Principles used to design</h3>
                  <p>Given the context of the students, they are very quick in accessing mobile while in the classroom or campus. The maximum percentage of the student age group tends to think faster than usual. To help them make better choices:</p>
                  <p>☝🏻 &nbsp;Efficient and user-centric design solution.</p>
                  <p>✌🏻 &nbsp;Aesthetic design (as beauty evokes emotion) to complement the usability of solution.</p>

                  <h3>Design criteria</h3>
                  <p>To create a mobile application that makes it easier for users to take a set of actions while on the go as mobile is available all the time. Given the context of the students, they are very quick in accessing mobile while in the classroom or campus. </p>

                  <div style={{backgroundColor:'#E4F2FF',borderRadius:'4px',padding:'16px 20px 16px 20px'}}>
                    <p>Covering all the features like:</p>
                    <p><b>Campus Feed</b> - Post and receive updates from campus pals.</p>
                    <p><b>Requests</b> - Request and get approvals from management for outings and leaves.</p>
                    <p><b>Circulars</b> - Receive official information (like some announcements, notices) from campus management.</p>
                    <p><b>Directory</b> - Access contacts of lecturers and management.</p>
                    <p><b>Grievances</b> - Report an issue and track the progress of resolution.</p>
                  </div>
                  <br/><br/>
                  <div style={{backgroundColor:'#ffede6',borderRadius:'4px',padding:'16px 20px 16px 20px'}}>
                    <p>These features will help us:</p>
                    <p>Save student's valuable time.</p>
                    <p>Make processes smarter.</p>
                    <p>Reduce waiting time for approvals.</p>
                    <p>Improve communication between faculty and students.</p>
                  </div>
                  <br/>
                  <br/>
                  <h3>Approaching the solution from task flows</h3>
                  <p>The platform helps students and management to initiate conversations, post circulars and report grievances, and more. As I was targeting to open up this platform for all the campus users. There are few challenges as we onboard different user types like Students, Lecturers, and other management staff. </p>
                  
                  <p>❓ "How might we make it easy for every campus user to get onboarded?"</p>
                  <p>❓ "How might we identify unique users on our platform?"</p>

                  <span>
                  I approached this challenge by breaking down it into its fundamentals.
                  <br/><br/>
                  → I know that every student will have a college registration number. It is unique to each student. Also, every management employee of the campus has a unique registration number. Combining the college registration number with the user's mobile number will help us to identify a unique user in our platform. For verification purposes, I collected these details from management to match the login entries.
                  <br/><br/>
                  → Below is one of the tasks where student will log in to the platform, raise an outing request and get it approved.
                  </span>

                  <br/><br/>

                  <h3>Task flow</h3>
                  <br/><br/>
                  <ZoomImg src={campus_taskflow} style={{width:'100%'}}/>
                  <br/><br/>

                  <h3>Wireflow</h3>
                  <br/><br/>
                  <ZoomImg src={campus_wireflow1} style={{width:'100%'}}/>
                  <span>Wireflow for creating a new request</span>
                  <br/><br/>

                  <ZoomImg src={campus_wireflow2} style={{width:'100%'}}/>
                  <br/><br/>
                  <span>Wireflow for reporting a grievance</span>
                  <br/><br/><br/>

                  <h3>Navigation</h3>
                  <br/><br/>
                  <ZoomImg src={campus_ia} style={{width:'100%'}}/>
                  <span>Architechture of the platform</span>
                  <br/><br/><br/>

                  <ZoomImg src={campus_cover1} style={{width:'100%'}}/>
                  <span>Visual</span>
                  <br/><br/><br/>

                  <h3>User validation</h3>
                  <p>After finishing up with basic features, I have launched the platform in the SVECW campus The platform turned out to be so viable among the users. I created 
                    a channel to receive continuous feedback from the users. I am commited to working on improving experience of the platform to fine-tune existing features and bring in new features.
                  </p>
                  <ZoomImg src={campus_visual} style={{width:'100%'}}/>
                  <span>Mobile app & website of the platform</span>
                  <br/><br/><br/>

                    <ZoomImg src={campus_visual2} style={{width:'100%'}}/>
                    <span>Smart campus platform web interface on iPad</span>
                    <br/><br/>
                  <p>As a platform, today Smart Campus facilitating smooth communication between students and management at SVECW campus. 
                  <br/><br/>It accords student's growth by addressing their needs on the campus and is on the journey to becoming a virtual assistant.
                  <br/><br/> A digital partner to campuses.</p>

                    
                  <div className='space'></div>

                    <br/>
                    <hr className="hr-text" data-content="Design has no end" />
                    <br/>
                    <br/>


                  </div>
                  </div>
                  
        
        <br/><br/>
    </div>

    )
  }

  

}
export default SmartCampus
