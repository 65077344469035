import React, {Component} from 'react'
import '../index.css'
import '../style.scss'
import '../App.css'
import ZoomImg from '../components/ZoomImg';
import { X } from 'react-feather';
var pavankusuma = require('../assets/profile_img.png');
var glass = require('../assets/glass.png');
var time = require('../assets/time.jpg');
var campus = require('../assets/campus_cover.jpg');
var ubrand = require('../assets/ubrand_cover1.jpg');
var insights = require('../assets/insights_cover.jpg');
var empathy_map = require('../assets/empathy_map.jpg');
var pavankusuma_resume = require('../assets/pavankusuma_resume.pdf');
var achievements = require('../assets/achievements.jpg');
var ms_cover = require('../assets/ms_cover.png');
var figmaplugin = require('../assets/figmaplugin.png');
var instagram = require('../assets/instagram.png');
var twitter = require('../assets/twitter.png');
var linkedin = require('../assets/linkedin.png');
var mail = require('../assets/mail.png');

class About extends Component {

  constructor(props){
    super(props)
    this.state = {
      myMap : false,
    }
    
  }
  showMap() {
    this.setState({
      myMap: !this.state.myMap,
    }, ()=> {
      console.log('okok')
      console.log(this.state.myMap)
    });
    // console.log('okok')
    // console.log(this.state.myMap)
}
navigateHome(){
    this.props.history.push('/')
  }
  render(){

    return(
  
    <div className="App">
        <center>
          <div style={{display:'flex', justifyContent:'center', gap:'40px'}}>
          <span><div onClick={this.navigateHome.bind(this)} style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}}>🛖 Home</div></span>
            <span><a href={pavankusuma_resume} style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}} target="_blank">📄 Resume</a></span>
            <span><u onClick={this.showMap.bind(this)} style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}}>👋🏻 Empathy map</u></span>
            <span><div style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}} target="_blank">ℹ️ About</div></span>
          </div>
          </center>
        <div style={this.state.myMap ? {display: 'flex', justifyContent: 'center'} : {display:'none'}}>
          <br/>
          <X size={24} style={{verticalAlign:'text-top', padding:'8px',color:'#666666',cursor:'pointer'}} onClick={this.showMap.bind(this)} />
          <ZoomImg src={empathy_map} style={{width:'60% !important'}}/>
        </div>
      
      
      
      <br/><br/><br/>
      <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', maxWidth:'100%', padding:'20px', gap:'40px'}}>
          <div style={{maxWidth:'440px'}}>
            <img src={pavankusuma} style={{maxWidth:'440px',maxHeight:'440px',objectFit:'cover',padding:'16px'}}/>
          </div>
          <div style={{maxWidth:'440px'}}>
            <p><h3>Pavan Kusuma</h3><br/>
            Designer based out of Bangalore. Believes - The purpose of design surfaces from human needs.<br/><br/>
            Currently working at Microsoft in Bangalore, India.<br/>
            Previously worked at Meredith Corporation and part of team that handled - Time person of the year poll.<br/><br/>
            When in college, I got fascinated about how internet and websites work. I used to nitpick some minute
slippages on every UI I come across. That eagerness helped me to learn new things at every stage through my digital journey
            </p>
          </div>
          </div>

      <br/><br/><br/>
      <hr className="hr-text" data-content="GET IN TOUCH" />

      <div style={{display:'flex', justifyContent:'center',textAlign:'left',padding:'16px', gap: '40px'}}>
        <a href='https://twitter.com/pavankusuma_/' target='_blank'><img src={twitter} style={{width:'24px', opacity:'0.6'}}/></a>
        <a href='https://www.instagram.com/pavankusuma/' target='_blank'><img src={instagram} style={{width:'24px', opacity:'0.6'}}/></a>
        <a href='https://www.linkedin.com/in/pavankusuma-ux/' target='_blank'><img src={linkedin} style={{width:'24px', opacity:'0.6'}}/></a>
        <a href='mailto:newtonpavan33@gmail.com'><img src={mail} style={{width:'24px', opacity:'0.6'}}/></a>
      </div>

      <br/><br/><br/>
      <center>© Pavan Kusuma</center>
    </div>

    )
  }

  

}
export default About
