import React, {Component} from 'react'
import { Mic, Disc } from 'react-feather';
import ZoomImg from '../components/ZoomImg';

var glass = require('../assets/glass.png');
var glass_overview = require('../assets/glass_overview.jpg');
var glass_research = require('../assets/glass_research.jpg');
var glass_usecase1 = require('../assets/glass_usecase1.jpg');
var glass_taskflow1 = require('../assets/glass_taskflow1.jpg');
var glass_usecase2 = require('../assets/glass_usecase2.jpg');
var glass_taskflow2 = require('../assets/glass_taskflow2.png');
var glass_view = require('../assets/glass_view.png');
var glass_notification = require('../assets/glass_notification.png');
var glass_show1 = require('../assets/glass_show11.jpg');
var glass_show2 = require('../assets/glass_show2.png');
var glass_show3 = require('../assets/glass_show3.png');
var glass_gif_1 = require('../assets/G_glass.gif');
var glass_gif_2 = require('../assets/G_glass1.gif');



class GoogleGlass extends Component {

  constructor(props){
    super(props)
    this.state = {
       
    }
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render(){

    return(
  
    <div className="App" id="fade-in1">  
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <a onClick={this.props.history.goBack} style={{cursor:'pointer'}}>Back</a>
          <span style={{alignContent:'end'}}>* Click on images to zoom in</span>
        </div> 
        {/* <p><a onClick={this.props.history.goBack}>Back</a> <span style={{alignContent:'end'}}>* Click on images to zoom in</span></p> */}
            <br/>
           
            <ZoomImg src={glass} style={{width:'100%'}}/>
            
            <div className='content_body'>
              
                <div style={{width:'600px'}}>
                <br/>
                  <br/>
                  <h1>Ok Glass,<br/>improve my bottom line</h1>
                  <br/>
                  <br/>
                  <h2>Proof of concept using Morden day tech Solutions - 2015</h2>
                  <br/><br/>
                  <br/>
                  <br/>
                  <h3>Vision</h3>
                  <p>Leveraging wearable technologies to help businesses optimize operations, simplify processes, and enhance users' lives.</p>
                  <br/>
                  <br/>
                  <h3>Background</h3>
                  <p>Google Glass — Glass is a small, lightweight wearable computer with a transparent display for hands-free work. It is fundamentally different than existing wearable devices in both design and use.</p>
                  <br/>
                  <br/>
                  <ZoomImg src={glass_overview} style={{width:'100%'}}/>
                  <br/>
                  <br/>
                  <p>We don't try to replace a smartphone, tablet, or laptop by transferring features designed for these devices to Glass. Instead, focusing on how Glass and our services complement each other and deliver an experience that is unique.</p>

                  <div className='space'></div>
                  
                  <h3>Research</h3>
                  <br/><br/>
                    <div style={{display: 'flex',flexWrap:'wrap',justifyContent:'center'}}>
                      <div>
                          <ZoomImg src={glass_research} style={{width:'100%'}}/>
                        </div>
                      <div style={{padding: '20px'}}>
                        <p>According to Juniper Research, approximately 70 million wearables devices will be sold around the world by 2017.</p>
                        <span>The study, “The Human Cloud: Wearable Technology from Novelty to Productivity”, was commissioned by Rackspace in association with the Centre for Creative and Social Technology (CAST) at Goldsmiths, University of London. The exploration was supported by quantitative research into attitudes and behaviour regarding wearable technology among 4,000 UK and US adults.</span>
                      </div>
                    </div>

                    <br/><br/>
                    <div style={{backgroundColor:'#F7FAFC',borderRadius:'4px',padding:'16px'}}>
                      <h3>✅ Key findings from the survey respondents who have used wearable technology:</h3>
                      
                        <ul>
                          <li><p>63 percent of UK and 71 percent of American respondents stated that wearable tech has improved their health and fitness.</p></li>
                          <li><p>One in three respondents in the UK and USA believe that wearable tech has helped their career development.</p></li>
                          <li><p>39 percent of UK respondents and 53 percent of US respondents say that wearable tech has made them feel more intelligent.</p></li>
                          <li><p>Wearable tech has boosted self-confidence for 46 percent of respondents in the UK and 54 percent in the US.</p></li>
                          <li><p>53 percent of respondents from the UK and 60 percent of those from the US believe that wearable tech helps them feel more in control of their lives.</p></li>
                          <li><p>27 percent of UK respondents and 36 percent of US respondents use wearable tech to enhance their love lives.</p></li>
                        </ul>
                        
                    </div>
                    <br/><br/>
                    <h4 style={{color:'#00627A !important'}}>“We are at the beginning of massive mainstream uptake of wearable devices, with the launch of Google Glass set to further boost adoption,”</h4>
                  
                    <div className='space'></div>
                    <h3>Opportunities</h3>
                    <p>At Infosys Digital, we starting looking into two use cases. We thought wearables can ease the tedious processes and enhance users' lives.</p>
                    
                    <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                      <div style={{backgroundColor:'#F7FAFC',borderRadius:'4px',padding:'16px',margin:'8px'}}>
                        <h3>1</h3>
                        <p>How might we improve the shopping experience for users with the freedom to compare on the go, save for later, and order? - <b>Consumers</b></p>
                      </div>
                      <div style={{backgroundColor:'#F7FAFC',borderRadius:'4px',padding:'16px',margin:'8px'}}>
                        <h3>2</h3>
                        <p>How might we help gather necessary information for insurance inspectors real quick? - <b>Businesses</b></p>
                      </div>
                    </div>


                    <div className='space'></div>
                  <br/>
                  <h2>Usecase 1</h2>
                  <p>How might we improve the shopping experience for users with the freedom to compare on the go, save for later, and order? - Consumers</p>
                  <ZoomImg src={glass_usecase1}  style={{width:'100%'}}/>
                  <br/><br/>
                  <h3>Scenario</h3>
                  <p>Use wearable tech to create a personalized shopping experience. Alice comes back from her busy day at the office and starts preparing for dinner. End of the day, she keeps her kitchen stocked up for the next day by ordering products that are out of stock.
                  <br/><br/>
                  <b>Context:</b> In the kitchen, cleaning the surface, putting vessels for washing
                  <b>Task:</b> Order products online
                  <b>Constraints:</b> Hands are busy and not clean, cannot access mobile while cleaning</p>
                  <br/><br/>
                  <h3>Task flow</h3>
                  <ZoomImg src={glass_taskflow1} style={{width:'100%'}} />
                  <br/><br/>
                  <p>Below image shows how in real time browsing happens on Google glass.</p>
                  <ZoomImg src={glass_view} style={{width:'100%'}} />

                  <br/><br/>
                  <p>Actions on the Glass are performed using 2 ways:</p>
                  <div style={{display:'flex',flexWrap:'wrap', justifyContent:'left', backgroundColor:'#F7FAFC',borderRadius:'4px',padding:'16px'}}>
                    <p style={{padding:'8px'}}><Mic size={16} style={{verticalAlign:'text-top'}} /> &nbsp; <b>Voice-command:</b> Say a command by speaking "Ok, Glass"</p>
                    <p style={{padding:'8px'}}><Disc size={16} style={{verticalAlign:'text-top'}} /> &nbsp; <b>Tap:</b> Tap on the side surface to interact with the projected interface</p>
                  </div>
                  <br/><br/>
                  <p>Considering the context of Alice, we primarily focused to build the feature to access via voice-commands. Also, we ensured that this feature is available to access via tapping on the surface.</p>
                  <br/>
                  <p>Here is how Alice places order using Google glass from kitchen while working.</p>
                  <ZoomImg src={glass_gif_1} style={{width:'100%'}} />
                  <span>Google Glass cards sequence</span>

                  <br/><br/>
                  <p>Alice receives updates about her order delivery on the glass and all the connected devices.</p>
                  <ZoomImg src={glass_notification} style={{width:'100%'}}/>
                  <p>She can opt to receive suggestions based on her previous purchases. Live comparison of products while on the go over the web is also possible now using wearable tech - Google glass.</p>




                  <div className='space'></div>
                  <br/>
                  <h2>Usecase 2</h2>
                  <p>How might we help gather necessary information for insurance inspectors real quick? - Businesses</p>
                  <ZoomImg src={glass_usecase2} style={{width:'100%'}} />
                  <br/><br/>
                  <h3>Scenario</h3>
                  <p>Ben has to go to a location to collect information about damage to an insurance claim.</p>
                  <br/><br/>
                  <h3>Task flow</h3>
                  <ZoomImg src={glass_taskflow2} style={{width:'100%'}} />
                  <br/><br/>
                  <p>Here is how Ben records the damage using Google Glass.</p>
                  <ZoomImg src={glass_gif_2} style={{width:'100%'}} />

                  <div className='space'></div>
                  <hr/>
                  <br/><br/>
                  
                  <p>We took the opportunity to share our work with the tech community. After showcasing our solutions inside the company, we went ahead to showcase at HYSEA (Hyderabad Software Enterprises Association). Many tech companies have shown interest.</p>
                  <ZoomImg src={glass_show1} style={{width:'100%'}} />
                  <span>Myself along with my team showcasing our Google Glass solution at HYSEA conference.</span>
                  <br/><br/>

                  <ZoomImg src={glass_show2} style={{width:'100%'}} />
                  <span>Our Infosys Digital kiosk at HYSEA conference.</span>
                  <br/><br/>

                  <ZoomImg src={glass_show3} style={{width:'100%'}} />
                  <span>Showing our Google Glass solution inside Infosys.</span>

                  <br/><br/>
                  <hr className="hr-text" data-content="End" />
                  

                  </div>
              </div>
        
        <br/><br/>
    </div>

    )
  }

  

}
export default GoogleGlass
