import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './routes/home'
import GoogleGlass from './routes/googleglass'
import SmartCampus from './routes/smartcampus'
import Ubrand from './routes/ubrand'
import Insights from './routes/insights'
import Time from './routes/meredith_work'
import About from './routes/about'

export default class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      
    }
  }


  render(){
    return (
      <Router>
        <Switch>
          <Route exact path="/meredith_work" component={Time} />
          <Route exact path="/google_glass" component={GoogleGlass} />
          <Route exact path="/smart_campus" component={SmartCampus} />
          <Route exact path="/u_brand" component={Ubrand} />
          <Route exact path="/insights" component={Insights} />
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
        </Switch>
      </Router>
    )
  }
}
