import React, {Component} from 'react'
import '../index.css'
import '../style.scss'
import '../App.css'
import ZoomImg from '../components/ZoomImg';
import { X } from 'react-feather';
var pavankusuma = require('../assets/profile_img.png');
var glass = require('../assets/glass.png');
var time = require('../assets/time.jpg');
var campus = require('../assets/campus_cover.jpg');
var ubrand = require('../assets/ubrand_cover1.jpg');
var insights = require('../assets/insights_cover.jpg');
var empathy_map = require('../assets/empathy_map.jpg');
var pavankusuma_resume = require('../assets/pavankusuma_resume.pdf');
var achievements = require('../assets/achievements.jpg');
var ms_cover = require('../assets/ms_cover.png');
var figmaplugin = require('../assets/figmaplugin.png');
var instagram = require('../assets/instagram.png');
var twitter = require('../assets/twitter.png');
var linkedin = require('../assets/linkedin.png');
var mail = require('../assets/mail.png');

class Home extends Component {

  constructor(props){
    super(props)
    this.state = {
      myMap : false,
    }
    
  }

  showMap() {
    this.setState({
      myMap: !this.state.myMap,
    }, ()=> {
      console.log('okok')
      console.log(this.state.myMap)
    });
    // console.log('okok')
    // console.log(this.state.myMap)
}

  navigateAbout(){
    this.props.history.push('/about')
  }

  navigateInsights(){
    this.props.history.push('/insights')
  }

  navigateSmartCampus(){
    this.props.history.push('/smart_campus')
  }

  navigateGoogleGlass(){
    this.props.history.push('/google_glass')
  }

  navigateUbrand(){
    this.props.history.push('/u_brand')
  }

  navigateTime(){
    this.props.history.push('/meredith_work')
  }

  render(){

    return(
  
    <div className="App">
        <center>
          <div style={{display:'flex', justifyContent:'center', gap:'40px', letterSpacing:'1px'}}>
            {/* <span><a href={pavankusuma_resume} style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}} target="_blank">📄 Resume</a></span>
            <span><u onClick={this.showMap.bind(this)} style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}}>👋🏻 Empathy map</u></span>
            <span><a href='#about' style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}} >ℹ️ About</a></span>
             */}
            <span><a href={pavankusuma_resume} style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}} target="_blank">Resume</a></span>
            <span><u onClick={this.showMap.bind(this)} style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}}>Empathy map</u></span>
            <span><a href='#about' style={{textDecoration:'none', fontWeight:'600', color:'#666666', cursor:'pointer'}} >About</a></span>
            
          </div>
          </center>
        <div style={this.state.myMap ? {display: 'flex', justifyContent: 'center'} : {display:'none'}}>
          <br/>
          <X size={24} style={{verticalAlign:'text-top', padding:'8px',color:'#666666',cursor:'pointer'}} onClick={this.showMap.bind(this)} />
          <ZoomImg src={empathy_map} style={{width:'60% !important'}}/>
        </div>
      <header className="App-header" style={{backgroundColor:'#f2f2f2 !important'}}>
      <img src={pavankusuma} style={{width:'175px',height:'175px',objectFit:'cover',padding:'16px'}}/>
        <h1>Pavan Kusuma</h1>
        {/* <div><p>A<mark> multidisiplinary designer </mark>. Cares about details in his work that includes from<mark> interaction</mark> +<mark> visual designs</mark>.</p></div> */}
        <div><p><b>UX, product and more!</b><br/> <i>Evaluating digital experiences for users to have less friction and become more productive.</i>.<br/> 
        {/* Believes - The purpose of design surfaces from <mark>human needs.</mark> */}
        {/* <br/> */}
        {/* Jack of <del>all</del> few trades. */}
        </p></div>
      </header>
      {/* <h4>Work</h4> */}
      <br/>
      
      {/* <center><i class="arrow down"></i></center> */}
      <hr className="hr-text" data-content="WORK" ></hr>
      {/* <br/> */}

      <div style={{display:'flex',flexWrap:'wrap', justifyContent:'center'}}>

        <div className='project_card' onClick={this.navigateInsights.bind(this)}>
          <img src={ms_cover} style={{width:'100%',height:'200px',objectFit:'cover', borderRadius:'12px 12px 0px 0px'}}/>
          <div style={{padding:'16px',margin:'10px'}}>
            <h3>Rich insights program for Microsoft commercial partners</h3>
            {/* <p>From concepts to wireframing and visuals, it was challenging to provide answers to business questions using clean dashboards.</p> */}
            <p>From brainstorming to visuals, I created end to end dashboards that answer complex business questions and has upto 60K MAUs.</p>
            <p style={{color:'#004dff'}}>Quick view →</p>
          </div>
        </div>

        <div className='project_card' onClick={this.navigateSmartCampus.bind(this)}>
          <img src={campus} style={{width:'100%',height:'200px',objectFit:'cover', borderRadius:'12px 12px 0px 0px'}}/>
          <div style={{padding:'16px',margin:'10px'}}>
            <h3>Digital solution to smoothly communicate for campuses</h3>
            <p>From research to product launch, I worked through user feedback to create a platform with good experience.</p>
            <p style={{color:'#004dff'}}>Quick view →</p>
          </div>
        </div>

        <div className='project_card' onClick={this.navigateGoogleGlass.bind(this)}>
          <img src={glass} style={{width:'100%',height:'200px',objectFit:'cover', borderRadius:'12px 12px 0px 0px'}}/>
          <div style={{padding:'16px',margin:'10px'}}>
            <h3>Ok Glass, improve my bottomline</h3>
            <p>Proof of concept for leveraging wearable technologies to help businesses optimize operations, simplify processes, and enhance users' lives.</p>
            <p style={{color:'#004dff'}}>Quick view →</p>
          </div>
        </div>

        <div className='project_card' onClick={this.navigateUbrand.bind(this)}>
          <img src={ubrand} style={{width:'100%',height:'200px',objectFit:'cover', borderRadius:'12px 12px 0px 0px'}}/>
          <div style={{padding:'16px',margin:'10px'}}>
            <h3>Helping brands to better plan their future business</h3>
            <p>Driving the process from pitching an idea of adding gamification into feedback system and market evaluation of it.</p>
            <p style={{color:'#004dff'}}>Quick view →</p>
          </div>
        </div>

        <div className='project_card' onClick={this.navigateTime.bind(this)}>
          <img src={time} style={{width:'100%',height:'200px', objectFit:'cover', borderRadius:'12px 12px 0px 0px'}}/>
          <div style={{padding:'16px',margin:'10px'}}>
            <h3>Time Inc. <br/>aka Meredith</h3>
            <p>Bunch of interaction + visual work in media domain. I curated experiences for content creators.</p>
            <p style={{color:'#004dff'}}>Quick view →</p>
          </div>
        </div>
        

      </div>
      
      
      <br/><br/>
      <hr className="hr-text" data-content="EXPERIENCE" />
      <div style={{display:'flex', justifyContent:'center',textAlign:'left',padding:'16px'}}>
        <div style={{width:'600px'}}>
          {/* <h4>Experience</h4> */}
          <h4>Microsoft India <span style={{fontSize:'16px'}}>Sep 2021 - present</span></h4>
          {/* <span>Sep 2021 - present</span><br/><br/> */}
          <p>• Providing deeper personalisation to make it easy for 68K+ MAUs to get the best from their Microsoft partnership.<br/>
          • Consistently ensuring partners to have a guided path in finding most relevant information and complete critical actions that impact their businesses.<br/>
          
          </p>
          <br/>
          <h4>Worked for Microsoft. (Payroll: Accenture India) <span style={{fontSize:'16px'}}>Dec 2018 - Sep 2021</span></h4>
          <p>
          • Created intuitive dashboards for Microsoft commercial partners.<br/>
          • Helping Microsoft Cloud Solution Partners to onboard with confidence<br/>
          • Boosted partners’ confidence with a smooth transition of 33K+ deal registrations into the new stack.<br/>
          • Curated consistent patterns for a cohesive experience for partners who drive million $ revenue to Microsoft. Today partners have more satisfied experience as we broke complex tasks into easy-to-perform actions that are intuitive and accessible.<br/>
          • Ensured a simplified and secure system to automate workflows.<br/>
          • Extended the experience of B2B platform to mobile to help users to take act quick on business proposals while on the go.<br/>
          <a href='https://play.google.com/store/apps/details?id=com.microsoft.partnercenter&hl=en_IN' target='_blank'>App link</a>
          
          </p>
          <br/>
          <h4>Meredith Corporation aka Time Inc, India <span>Feb 2017 - Dec 2018</span></h4>
          <p>• Designed a Suite of products for a seamless advertising platform.<br/>
          • Came up with a game idea that captures ample user insights to understand user behaviour better.<br/>
          • Served more than 15+ projects delivering meaningful interactions in quick deadlines by working with cross functional teams.<br/>
          
          </p>
          <br/>
          <h4>Infosys Limited, India <span>Dec 2011 - Feb 2017</span></h4>
          <p>• Reformed the experience to organise and facilitate collaborative content creation for multinational and largest networking company in the world.<br/>
          • Pitched proposals for consumer market with Google Glass, Android gear solutions.<br/>
          • Conducted usability and accessibility assessment for telecommunications company website.<br/>
          
          </p>
        </div>
      </div>
      
      <br/><br/>
      <hr className="hr-text" data-content="ACHIEVEMENTS" />
      <div style={{display:'flex', justifyContent:'center',textAlign:'left',padding:'16px'}}>
        <div style={{width:'600px'}}>
        {/* <h4>Achievements</h4><br/> */}
        <img src={achievements} style={{width:'100%'}}/>
        </div>
      </div>
      
      {/* <br/><br/><br/>
      <hr className="hr-text" data-content="SKILLSET" />
      <div style={{display:'flex', justifyContent:'center',textAlign:'left',padding:'16px'}}>
        <div style={{width:'600px'}}>
          
          <p><b>User experience tools & methods</b><br/>
            I believe that problems do have solutions but the quest for the good solution is where design tools can help us. I am good at interaction and visual design. I strongly believe that every interaction and visual design decisions should be taken after deep understanding of users.<br/>
          </p>
          
          <p><b>Developer & Tech knowledge</b><br/>
          I was passionate coder and have a good idea about the power of tech and its possibilities. That helps me in designing solutions that are holistic.
          </p>
          
          <p><b>Public speaking</b><br/>
          I addressed people at different podiums and I coach graduate students for their college projects.
          </p>
        </div>
      </div> */}

      {/* <br/><br/><br/>
      <hr className="hr-text" data-content="TOOLS" />
      <div style={{display:'flex', justifyContent:'center',textAlign:'left',padding:'16px'}}>
        <div style={{width:'600px'}}>
          
          <p><b>Figma</b><br/>
          Amazing for quick interactions and visual design.<br/>
          </p>
          
          <p><b>Sketch App</b><br/>
          killer of Photoshop for quick wireframe, interactions and visual design.
          </p>
          
          <p><b>Adobe Suite</b><br/>
          From Photoshop to XD.<br/>
          Adobe Photoshop, Adobe Illustrator, Adobe InDesign
          </p>
          
          <p><b>Other</b><br/>
          Invision Studio, Framer, Zeplin
          </p>

          
        </div>
      </div> */}
      
      <br/><br/><br/>
      <hr className="hr-text" data-content="WRITE UPS" />
      <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-evenly', maxWidth:'100%', padding:'20px'}}>
          <div style={{maxWidth:'440px'}}>
            <p><h3>My story of a cover page for the Figma file</h3><br/>
            Overwhelmed with countless number of files in your drafts? Cover Page plugin puts a face to your figma files.<br/><br/>
            <a style={{color:'#004dff', textDecoration:'none'}} href="https://uxdesign.cc/my-story-of-a-cover-page-for-the-figma-file-3435ee13ef27?sk=32884f9de443a0dabc36c1e6e492992c" >Read article →</a>
            </p>
          </div>
          <div style={{maxWidth:'440px'}}>
            <img src={figmaplugin} style={{width:'100%'}}/>
          </div>
          </div>

      <br/><br/><br/>
      {/* <hr className="hr-text" data-content="GET IN TOUCH" />

      <div style={{display:'flex', justifyContent:'center',textAlign:'left',padding:'16px', gap: '40px'}}>
        <a href='https://twitter.com/pavankusuma_/' target='_blank'><img src={twitter} style={{width:'24px', opacity:'0.6'}}/></a>
        <a href='https://www.instagram.com/pavankusuma/' target='_blank'><img src={instagram} style={{width:'24px', opacity:'0.6'}}/></a>
        <a href='https://www.linkedin.com/in/pavankusuma-ux/' target='_blank'><img src={linkedin} style={{width:'24px', opacity:'0.6'}}/></a>
        <a href='mailto:newtonpavan33@gmail.com'><img src={mail} style={{width:'24px', opacity:'0.6'}}/></a>
      </div>

      <br/><br/><br/> */}


<hr className="hr-text" data-content="ABOUT" id="about" />
      <div style={{backgroundColor:'#f2f2f2'}}>
          <br/><br/><br/>
          <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center',  padding:'20px', gap:'40px'}}>
              <div >
                <img src={pavankusuma} style={{objectFit:'cover',width:'100%'}}/>
              </div>
              <div style={{maxWidth:'440px'}}>
                <p><h3>Pavan Kusuma</h3><br/>
                Designer based out of Bangalore. Believes - The purpose of design surfaces from human needs.<br/><br/>
                Currently working at Microsoft in Bangalore, India.<br/>
                Previously worked at Meredith Corporation and part of team that handled - Time person of the year poll.<br/><br/>
                When in college, I got fascinated about how internet and websites work. I used to nitpick some minute
    slippages on every UI I come across. That eagerness helped me to learn new things at every stage through my digital journey
    <br/>
    <br/>
    <div style={{display:'flex', justifyContent:'start',textAlign:'left',padding:'16px', gap: '40px'}}>
        <a href='https://twitter.com/pavankusuma_/' target='_blank'><img src={twitter} style={{width:'24px', opacity:'0.6'}}/></a>
        <a href='https://www.instagram.com/pavankusuma/' target='_blank'><img src={instagram} style={{width:'24px', opacity:'0.6'}}/></a>
        <a href='https://www.linkedin.com/in/pavankusuma-ux/' target='_blank'><img src={linkedin} style={{width:'24px', opacity:'0.6'}}/></a>
        <a href='mailto:newtonpavan33@gmail.com'><img src={mail} style={{width:'24px', opacity:'0.6'}}/></a>
      </div>
                </p>
                
              </div>
              </div>

          <br/><br/><br/>
          
      </div>
      
    </div>

    )
  }

  

}
export default Home
