import React, {Component} from 'react'
import { Mic, Disc } from 'react-feather';
import ZoomImg from '../components/ZoomImg';

var glass = require('../assets/glass.png');
var glass_overview = require('../assets/glass_overview.jpg');
var ubrand_cover = require('../assets/ubrand_cover1.jpg');
var ubrand_graph = require('../assets/ubrand_graph.png');
var ubrand_graph2 = require('../assets/ubrand_graph2.png');
var ubrand_questions = require('../assets/ubrand_questions.png');
var ubrand_screens1 = require('../assets/ubrand_screens1.png');
var ubrand_screens2 = require('../assets/ubrand_screens2.png');
var ubrand_paperclip = require('../assets/ubrand_paperclip.png');
var ubrand_sketch = require('../assets/ubrand_sketch.png');


class Ubrand extends Component {

  constructor(props){
    super(props)
    this.state = {
       
    }
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render(){

    return(
  
    <div className="App" id="fade-in1">  
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <a onClick={this.props.history.goBack} style={{cursor:'pointer'}}>Back</a>
          <span style={{alignContent:'end'}}>* Click on images to zoom in</span>
        </div> 
        {/* <p><a onClick={this.props.history.goBack}>Back</a> <span style={{alignContent:'end'}}>* Click on images to zoom in</span></p> */}
            <br/>
           
            <ZoomImg src={ubrand_cover} style={{width:'100%'}}/>
            
            <div className='content_body'>
              
                <div style={{width:'600px'}}>
                <br/>
                  <br/>
                  <h1>Helping brands to better plan their future business</h1>
                  <br/>
                  <br/>
                  <h2>Gamified feedback system</h2>
                  
                  <p>An interactive solution to customer engagement for premium brands. This solution enables brands to understand the customer's pulse which helps to better plan future business.</p>
                  <br/>
                  <br/>
                  <h3>Intro</h3>
                  <p>Here is a design story about how I and my teams worked overnight to come up with a solution to a problem at a 36 hours Hackathon.</p>
                  <br/>
                  <br/>
                  <h3>Theme</h3>
                  <p>From a list of problem statements, each team must pick anyone and start working for straight 36 hours to solve it and pitch the solution.</p>
                  <div style={{backgroundColor:'#EEEEEE',borderRadius:'4px',padding:'16px 20px 16px 20px'}}>
                    <span>We picked up one that says</span>
                    <br/><br/>
                    <h4>"Improving a brand business"</h4>
                  </div>
                  <br/><br/>
                  <p>We came up with a plan to divide our time and focus on the below areas to better approach the problem and accomplish the task in hand.</p>
                  <div style={{backgroundColor:'#E4F2FF',borderRadius:'4px',padding:'6px 20px 6px 20px'}}>
                    <p>Our approach</p>
                      <p>1. Understanding the problem - Problem</p>
                      <p>2. Break down to fundamentals - Thoughts & Research</p>
                      <p>3. Create MVP - Wireframes & Building</p>
                      <p>4. Evaluate - Market test</p>
                    
                  </div>
                  <div className='space'></div>
                  <h3>Problem</h3>
                  <p>Every quarter brands introduce new products to the brand outlet shelves in the market. Every product shipped from a brand comes with a huge cost. Knowing the audience's pulse just before it hits the production line is quite important. 
                  <br/><br/>
                  <b>❓ How to collect feedback in the best possible way from the target audience.</b></p>
                  <br/>
                  <br/>
                  <h3>Thoughts & approach</h3>
                  <p>✴️  &nbsp;Good feedback is the key to improvement.</p>
                  <p>As the competition is time-bound, we took the chance to do primary(desktop) research. Also, everyone took time to pen down all the assumptions around <i><mark>how brands to business today,</mark> <mark> collect feedback from customers,</mark> <mark> plan their future business</mark>, and<mark> the scale of their business.</mark></i></p>
                  <p>By 2014, Nike as 858 retail stores, and 66.1% of its business is from footwear. The footwear business is pioneering in billion-dollar USD today and projected to grow even more.</p>
                  <ZoomImg src={ubrand_graph} style={{width:'100%'}}/><br/>
                  <p>To stay in the competition, every <b>brand</b> is <b>busy working</b> on their <b>future product</b> editions and how to introduce new versions of their products to the shelves in their outlets. Every brand has some bugging questions like below</p>
                  <ZoomImg src={ubrand_questions} style={{width:'100%'}}/><br/>
                  <p>Every brand cares about its product life cycle and how to keep the sales growth upward.</p>
                  <ZoomImg src={ubrand_graph2} style={{width:'100%'}}/><br/>
                  <p>From our initial research, we found out that there are many things that impact the product's life cycle. Few of them lead to a product's success and few can lead to its failure. Here is what we understood.
                    <br/>
                    Knowing the audience's pulse just before they hit the production line is quite important in estimating the following:</p>

                    <div style={{backgroundColor:'#E4F2FF',borderRadius:'4px',padding:'6px 32px'}}>
                      <p>📈  &nbsp;How large would be the demand quantity?</p>
                      <p>👍🏼️  &nbsp;Likability of the list of products.</p>
                      <p>❇️  &nbsp;Ranking for each product from the list that is going to production line.</p>
                  </div>
                  <br/>
                  <br/>
                  <p>The critical feedback at this moment can help save</p>
                  <div style={{backgroundColor:'#E4F2FF',borderRadius:'4px',padding:'6px 32px'}}>
                      <p>⬇️️  &nbsp;Additional manufacturing costs.</p>
                      <p>✴️️  &nbsp;Production quantity of each product.</p>
                      <p>♻️️  &nbsp;Thus reducing wastage of inventory.</p>
                  </div>
                  <br/>
                  <br/>
                  <p>→ Next step is to understand how brands collect feedback today.<br/>We know brands today are in need of customer feedback on their products in the market. They use different methods/channels to seek customer feedback. Here are some of them:</p>
                  <div style={{backgroundColor:'#ffeef8',borderRadius:'4px',padding:'6px 32px',display:'inline-block'}}>
                      <p>📋  &nbsp;Surveys</p>
                      <p>📨  &nbsp;Email and contact forms</p>
                      <p>📝  &nbsp;Usability tests</p>
                      <p>🌏  &nbsp;Instant feedback on website</p>
                      <p>👍🏼  &nbsp;Social media</p>
                      <p>🗣  &nbsp;Direct customer interviews</p>
                      <p>🙌🏼  &nbsp;Consumer forums</p>
                  </div>
                  <br/>
                  <br/>
                  <p>Over the years, from all the different methods of collecting feedback, it is observed that giving and taking feedback is uncomfortable. Mainly when taking feedback from customers, brands lack to engage them enough.</p>
                  <div className='space'></div>
                  <h3>Insights</h3>
                  <p>The other side of the story is brands don't know whether customers are happy giving feedback from these channels. Here is what customers are thinking and experiencing when they feedback across different methods.</p>
                  <div style={{backgroundColor:'#f5f5f5',borderLeft:'4px solid #0084FF',borderRadius:'4px',padding:'4px 32px'}}>
                      <p>1. Lack of interest</p>
                      <p>2. Frustrated to buy-in time</p>
                      <p>3. Make them feel awkard and vulnerable</p>
                      <p>4. Feel pointless</p>
                      <p>5. Not rewarded anyway</p>
                  </div>
                  <br/>
                  <br/>
                  <h3>Vision</h3>
                  <p>How might we help brands to collect sensible feedback from customers using gamification.</p>
                  <br/>
                  <br/>
                  <h3>Criteria</h3>
                  <p>Use the <b>Hot or Not gaming concept</b> to understand the attractiveness of a product and to rank it among a list of other similar products.
                    More about the concept: <a href="https://en.wikipedia.org/wiki/Hot_or_Not" target="_blank">https://en.wikipedia.org/wiki/Hot_or_Not</a></p>
                  <br/>
                  <div style={{backgroundColor:'#E4F2FF',borderRadius:'4px',padding:'6px 32px'}}>
                  
                    <p>Things to take care when seeking feedback:</p>
                    <p>• Good structure is backbone of a feedback.</p>
                    <p>• Identify target audience.</p>
                    <p>• Don't hesitate to ask for feedback on something specific you want.</p>
                    <p>• Ask it at the moment. This helps to keep it on top of the mind. Making sure the timing is right is the key. That will ensure much higher response and engagement.</p>
                    <p>• Limit the focus of customer.</p>
                    <p>• Appreciate the feedback.</p>
                    <p>• Incentify the customer.</p>
                  
                  </div>
                  <br/>
                  <br/>
                  <h3>Idea</h3>
                  <p>Hot or Not concept turns out to be a quick A/B testing approach with customers at the brand outlets.</p>
                  <br/>
                  <div style={{backgroundColor:'#E4F2FF',borderRadius:'4px',padding:'6px 32px'}}>
                  
                    <p>Here goes the idea</p>
                    <p>1. Gather the list of future products and combine it with existing products</p>
                    <p>2. When customers does purchases in brand outlets and while at billing counters.</p>
                    <p>3. Brand outlet owner can offer our solution to customer while he processing his bill.</p>
                    <p>4. As it a gamification empowered app, customer has a game to play.</p>
                    <p>5. The game shows two products at a time, customer should tap on anyone to select it</p>
                    <p>6. The selected product is compared with the next product from the list.</p>
                    <p>7. This process is repeated until the customer reaches the end of the product list and eventually he ends up with his best choice out of all the products on the list.</p>
                    <p>8. Brand offers a token of appreciation for providing feedback.</p>
                  
                  </div>
                  <br/>
                  <br/>
                  <div style={{backgroundColor:'#f5f5f5',borderLeft:'4px solid #0084FF',borderRadius:'4px',padding:'4px 32px'}}>
                    <p>This process is repeated with each customer who makes a purchase from all the brand outlets and their valuable feedback is collected. Every product is given a score based on the number of times it came as the customer's best choice.</p>
                  </div>
                  <br/>
                  <br/>
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/htqaV1ARgGk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  <span>Concept clip to our solution</span>
                  <br/>
                  <br/>
                  <div style={{backgroundColor:'#ffeef8',borderRadius:'4px',padding:'6px 32px'}}>
                    <p>So in just 1 month, 96,000 opinions from A/B testing are collected if 20 users per day take this app survey from 10 brand outlets.</p>
                  </div>
                  <br/>
                  <br/>

                  <h3>MVP</h3>
                  <p>We started building an Android mobile application for this solution as a minimum viable product. He researched to check how Tap-games work and engage users. Also, we checked out the hot-or-not game that Zukerberg built out of his college dorm.
                  <br/>
                  <br/>
                  <b>App has two flows:</b> one for customers and other for brand outlet managers.
                  <br/>
                  We made a simple flow for customers to play the game and provide their feedback.</p>
                  <ZoomImg src={ubrand_sketch} style={{width:'100%'}}/>
                  <span>Task flow & wire sketches</span>
                  <br/>
                  <br/>
                  <ZoomImg src={ubrand_screens1} style={{width:'100%'}}/>
                  <span>Minimum viable product screen flow for customers</span>
                  <br/>
                  <br/>
                  <ZoomImg src={ubrand_screens2} style={{width:'40%'}}/>
                  <span>Retail outlet managers get to see statistics of all the feedback collected.</span>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                  <h3>Evaluate</h3>
                  <p>We went out to test our app at real brand outlets. We pitched our solution to outlet owners and explained how this can help in taking decisions based on customer inputs. We received positive feedback from many outlet owners and they are ready for the pilot run of this app at their outlet. Brand outlet owners found potential in our solution and believed it might improve their business.
                    <br/><br/><b>Our solution and the process we followed in approaching the solution helped us in winning the Hackathon and many more.</b></p>
                    <div style={{backgroundColor:'#ffeef8',borderRadius:'4px',padding:'6px 32px'}}>
                      <p>🏆️️  Winner of Startup Weekend Hyderabad - 2014</p>
                      <p>🏅️️  One of The Top 14 Finalists In Global Startup Battle World</p>
                      <p>🏆️️  Winner of Infosys Convergence - 2015</p>
                    </div>
                    <br/><br/>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/I9NPDVAE5Gw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <span>Promotional clip to our solution</span>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  
                    <ZoomImg src={ubrand_paperclip} style={{width:'100%'}}/>
                    <span>Featured in Deccan Chronicle, 2014</span>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <hr className="hr-text" data-content="Design has no end" />
                    <br/>
                    <br/>

                  </div>
                  </div>
                  
        
        <br/><br/>
    </div>

    )
  }

  

}
export default Ubrand
